
import { defineComponent, watch, ref, onMounted, onBeforeUnmount } from "vue";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { useI18n } from "vue-i18n";
import { Mutations } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { statusColor, statusIcon } from "@/core/services/HelpersFun";
import { AllBots } from "@/store/pinia/AllBots";
// import Chart from "@/components/Chart.vue";
// import ReportChart from "@/components/widgets/charts/ReportChart.vue";

export default defineComponent({
  name: "view-request-report-modal",
  components: {
    // ReportChart
  },
  props: {
    id: {
      required: true,
      type: Number,
    },
  },
  setup(props) {
    const { t, te } = useI18n();
    const translate = (text) => {
      if (te(text)) return t(text);
      return text;
    };

    const store = useStore();
    const loading = ref(false);
    const list = ref();
    const route = useRoute();
    const getList = () => {
      //   loading.value = true;
      //   const endPoint = route.name === "manageRequest"
      //       ? `/admin/request/${props.id}/numbers?with_platforms=true&page=${page.value}`
      //       : `/request/${props.id}/numbers?with_platforms=true&page=${page.value}`;

      //   ApiService.get(endPoint)
      //       .then((data) => {
      //         list.value =  data.data.data;
      //         loading.value = false;
      //         pagination.value = list.value.numbers.pagination;
      //       })
      //       .catch(({response}) => {
      //         if (response && response.data)
      //           store.commit(Mutations.SET_ERROR, response.data);
      //       });
      loading.value = true;
    //   const endPoint =
    //     route.name === "manageRequest"
    //       ? `/admin/request/${props.id}/numbers?with_platforms=true&page=${page.value}`
    //       : `/request/${props.id}/numbers?with_platforms=true&page=${page.value}`;

      ApiService.get('/crawler/request/'+ props.id)
        .then((data) => {
          list.value = data.data.data;
          loading.value = false;
          pagination.value = list.value.numbers.pagination;
        })
        .catch(({ response }) => {
          if (response && response.data)
            store.commit(Mutations.SET_ERROR, response.data);
        });
    };

    const interval = ref();
    onMounted(() => {
      interval.value = setInterval(() => {
        if (props.id && list.value?.request?.crawling_status !== "done")
          getList();
      }, 60000);
    });
    onBeforeUnmount(() => {
      clearInterval(interval.value);
    });

    watch(props, () => {
      getList();
    });

    // pagination
    const page = ref(1);
    const pagination = ref();
    const handleCurrentChange = (val: number) => {
      page.value = val;
      getList();
    };

    const getFiltersData = (type, column, value) => {
      let data = list.value.request.filters.filter(
        (item) => item.type === type && item[column] == value
      );
      let names = data.map((item) => {
        if (item.display_name) return item.display_name;
      });

      if (names.length > 0) return names.join(" ,");
    };

    const getFiltersDataCategory = (type, column, value) => {
      let data = list.value.request.filters.filter(
        (item) => item.type === type && item[column] == value
      );
      let names = data.map((item) => {
        if (item.display_name) {
          let find = item.filter.list_items.find((fi) => fi.key === item.value);
          return {
            name: item.filter.name,
            value:
              find !== undefined
                ? item.filter.list_items.find((fi) => fi.key === item.value)
                    ?.title
                : item?.value,
          };
        }
      });
      if (names.length > 0) return names;
    };

    const findBotPlatform = (platforms = []) => {
      if (!platforms) return null;
      return platforms.find(
        (item) => item["platform"] === list.value.request.type
      );
    };

    const AllBotsHolder = AllBots();
    // const { disconnect } = AllBotsHolder;

    const getLastCrawledDate = (bots, platform) => {
      if (bots.length < 1) return;
      let find = bots.find((item) => item.platform === platform);
      if (find) return find?.last_crawl_date?.jalali;
    };

    return {
      translate,
      statusColor,
      list,
      loading,
      pagination,
      handleCurrentChange,
      getList,
      JwtService,
      getFiltersData,
      getFiltersDataCategory,
      findBotPlatform,
      //   disconnect,
      statusIcon,
      getLastCrawledDate,
    };
  },
});


import { defineComponent, onMounted, ref } from "vue";
import Report from "./components/Report.vue";
// import UserAvatar from "@/components/UserAvatar.vue";
// import {requestStatus} from "@/core/services/HelpersFun";
import userInfo from "@/core/services/UserInfo";
import JwtService from "@/core/services/JwtService";
import { statusColor } from "@/core/services/HelpersFun";
import { useI18n } from "vue-i18n";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import CreateRequest from "./components/CreateRequestForm.vue";
import { platforms } from "@/store/pinia/Platforms";
import { AllBots } from "@/store/pinia/AllBots";
import { requests } from "@/store/pinia/Requests";
// import { requests } from "@/store/Pinia/Requests";
import { storeToRefs } from "pinia";
export default defineComponent({
  name: "requests-list",
  components: {
    Report,
    // UserAvatar,
    CreateRequest,
  },
  setup() {
    const { t, te } = useI18n();
    const translate = (text) => {
      if (te(text)) return t(text);
      return text;
    };

    const selectedId = ref();
    const user = userInfo.getUser();
    const req_modal = ref(false);
    const report_modal = ref(false);
    const requestsHolder = requests();
    const {
      handleCurrentChange,
      fetchRequests,
      changeStatus,
      deleteRequest,
      refresh,
    } = requestsHolder;

    const { key, loading, list, route, filter, page, pagination } =
      storeToRefs(requestsHolder);

    const platformsHolder = platforms();
    const { crowlerPlatformsList } = storeToRefs(platformsHolder);
    const { fetchCrowlerPlatforms } = platformsHolder;

    const AllBotsHolder = AllBots();
    const { crowlerBotList } = storeToRefs(AllBotsHolder);
    const { fetchCrowlerBotList } = AllBotsHolder;

    onMounted(() => {
      fetchCrowlerBotList()
      fetchCrowlerPlatforms();
      fetchRequests();
      setCurrentPageTitle("مدیریت استخراج شماره");
    });

    return {
      statusColor,
      translate,
      handleCurrentChange,
      fetchRequests,
      changeStatus,
      deleteRequest,
      refresh,
      // deleteBotFunc,

      list,
      loading,
      pagination,
      page,
      JwtService,
      route,
      filter,
      crowlerPlatformsList,
      // requestStatus,
      crowlerBotList,
      user,
      key,
      selectedId,
      req_modal,
      report_modal,
    };
  },
});

import { defineStore } from "pinia";
import { reactive, ref } from "vue";
import ApiService from "@/core/services/ApiService";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { Mutations } from "@/store/enums/StoreEnums";
export const requests = defineStore("requestsInfo", () => {
    // state
    const key = ref(1);
    const store = useStore();
    const loading = ref(false);
    const list = ref();
    const route = useRoute();

    const filter = reactive({
        status: "",
        bot_id: "",
        q: "",
        platform: "",
    });
    const page = ref(1)
    const pagination = ref();

    // getters

    // actions
    const handleCurrentChange = (val: number) => {
        page.value = val;
        fetchRequests();
    };

    const fetchRequests = () => {
        const endPoint = `/crawler/request?page=${page.value}`;
        loading.value = true;
        ApiService.get(endPoint)
            .then((data) => {
                console.log(data);
                list.value = data.data.data.crawler_requests.data;
                pagination.value = data.data.data.crawler_requests.pagination;

            })
            .catch(({ response }) => {
                if (response && response.data) store.commit(Mutations.SET_ERROR, response.data);
            })
            .finally(() => {
                loading.value = false;
            })
            ;
    }

    const changeStatus = (id, status) => {
        const endPoint = route.name === "manageRequest"
            ? `/admin/request/${id}/change-status`
            : `/request/${id}/change-status`;

        loading.value = true;
        ApiService.post(endPoint, {
            status: status
        })
            .then(() => {
                fetchRequests();
            })
            .catch(({ response }) => {
                if (response && response.data) store.commit(Mutations.SET_ERROR, response.data);
            })
            .finally(() => {
                loading.value = false
            })
            ;
    }

    const deleteRequest = (id) => {
        ApiService.post(`admin/agent/${id}/delete`, {})
            .then(() => {
                fetchRequests();
            })
            .catch(({ response }) => {
                if (response && response.data) store.commit(Mutations.SET_ERROR, response.data);
            })
    }

    const refresh = () => {
        fetchRequests();
        key.value++;
    }

    return {
        key,
        store,
        loading,
        list,
        route,
        filter,
        page,
        pagination,
        handleCurrentChange,
        fetchRequests,
        changeStatus,
        deleteRequest,
        refresh,
    };
});
